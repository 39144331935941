import logo from './logo.svg';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAndroid, faApple, faLinux, faMicrosoft } from '@fortawesome/free-brands-svg-icons'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Rejoice Bible App</h1>

        <div class="layout">
          <div class="layout__item layout__item--body">
            <h3>Coming Soon</h3>
            <p>
             A new Bible app which is easy to use with no adverts completely free!
            </p>
            <p>
              Designed for accessibility and user-friendliness.
            </p>
            <p>
              Experience the scripture through text, audio and video. 
            </p>
            <p>
              Situations allow you to easily find scripture that is relevant to the situation. 
            </p>
            <p>
              A truly cross-platform app designed for your mobile, tablet and desktop devices.
            </p>
          </div>
          <div class="layout__item layout__item--figure">
            <img src='img/home.png' alt="homepage" />
          </div>
        </div>

        <div>
          <h2>Supported Platforms</h2>
          <div >
            <ul class="no-bullets">
              <li><FontAwesomeIcon icon={faAndroid} /></li>
              <li><FontAwesomeIcon icon={faApple} /></li>
              <li><FontAwesomeIcon icon={faLinux} /></li>
              <li><FontAwesomeIcon icon={faMicrosoft} /></li>
              <li><FontAwesomeIcon/>WWW</li>
            </ul>
          </div>
         

        </div>

      </header>
      <body>
      </body>
    </div>
  );
}

export default App;
